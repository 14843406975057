@use '../mixins' as *;

:root {
  --map-offset: 0;
  --map-width: 100%;
  --map-height: 32rem;
}

@include breakpoint-min(medium) {
  :root {
    --map-height: 38rem;
  }
}
