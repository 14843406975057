@use '../mixins' as *;

:root {
  --page-margin: 1.25rem;
  --page-margin-negative: -1.25rem;
  --grid-columns: 12;
  --grid-gutter: 1.25rem;
  --content-width: calc(100% - var(--page-margin) * 2);
  --content-max-width: 90rem; // 1440px
}

@include breakpoint-min(medium) {
  :root {
    --page-margin: 1.25rem;
    --page-margin-negative: -1.25rem;
  }
}
