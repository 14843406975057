@use 'mixins' as *;

html {
  background-color: var(--color-page-background, inherit);
}

body {
  font-family: var(--font-family, system-ui, sans-serif);
  background: var(--color-page-background, inherit);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &[data-scroll-locked] {
    display: inline;
  }
}

*:focus-visible {
  outline: thin dashed var(--color-focus, inherit);
}

a {
  svg,
  img {
    display: inline-block;
  }
}
