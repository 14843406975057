/* Main */
:root {
  --header-height: 64px;
  --map-offset: 0;
  --map-width: 100%;
  --map-height: 500px;
  --map-height-desktop: 600px;
  --scroll-margin: calc(var(--header-height) + 20vh);
}

// Colors
:root {
  --color-black: #111111;
  --color-black-hover: #2d2d2d;
  --color-gray0: #74747f;
  --color-gray1: #90909b;
  --color-gray2: #b3b4bc;
  --color-gray3: #dedede;
  --color-gray4: #eaeaea;
  --color-gray5: #f8f8fc;
  --color-gray6: #f7f7f7;
  --color-hell-active: #5686ff;
  --color-hell-blue: #0000ff;
  --color-hell-blue-hover: #5f5fff;
  --color-hell-no: #ff1e1e;
  --color-hell-salmon: #fa826e;
  --color-hell-salmon-hover: #ea7966;
  --color-hell-salmon-light: #fcc0b7;
  --color-hell-turquoise: #00ffc8;
  --color-hell-turquoise-hover: #07e2b3;
  --color-hell-turquoise-light: #80ffe3;
  --color-hell-yeah: #67fc6d;
  --color-overlay-transparent: rgba(35, 33, 32, 0.3);
  --color-white: #ffffff;
  --color-yellow: rgba(242, 201, 76, 1);
}

// Font families
:root {
  --font-hero: var(--font-hero);
  --font-regular-bold: var(--font-bold);
  --font-family-regular: var(--font-regular);
  --font-family: var(--font-regular);
}

// Font sizes
:root {
  --font-size-body10: 10px;
  --font-size-body12: 12px;
  --font-size-body14: 14px;
  --font-size-body16: 16px;
  --font-size-h1: 3rem;
  --font-size-h2: 2.5rem;
  --font-size-h3: 2rem;
  --font-size-h4: 1.25rem;
  --font-size-header1: 40px;
  --font-size-header2: 34px;
  --font-size-header3: 26px;
  --font-size-header4: 20px;
  --font-size-l: 1.25rem;
  --font-size-m: 1rem;
  --font-size-mega: 60px;
  --font-size-microcopy: 0.625rem;
  --font-size-s: 0.75rem;
}

// Font weights
:root {
  --font-weight-bold: 900;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
}

// Line heights
:root {
  --line-height-body10: 16px;
  --line-height-body12: 18px;
  --line-height-body14: 20px;
  --line-height-body16: 24px;
  --line-height-header1: 46px;
  --line-height-header2: 40px;
  --line-height-header3: 32px;
  --line-height-header4: 26px;
  --line-height-l: 1.7;
  --line-height-m: 1.5;
  --line-height-mega: 66px;
  --line-height-s: 1.25;
  --line-height-xs: 1;
}

// Spacing
:root {
  --spacing-xl: 192px;
  --spacing-block: 32px;
  --spacing-large: 96px;
  --spacing-medium: 48px;
  --spacing-menu-height: 64px;
  --spacing-mobile-top: 72px;
  --spacing-regular: 24px;
  --spacing-small: 16px;
  --spacing-tinier: 8px;
  --spacing-tiny: 8px;
  --spacing-xsmall: 8px;
  --spacing-xxsmall: 4px;
}

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  background-repeat: no-repeat; /* 2 */
}
html {
  font-size: 16px;
  font-weight: var(--font-weight-regular);
  min-height: 100vh;
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: var(--font-weight-regular);
}

#root {
  min-height: 100vh;
}

img {
  max-width: 100%;
}

.no-js img.lazyload {
  display: none;
}

.map {
  position: relative;
  width: 100%;
}
h1,
h2,
h3,
h4,
h6,
p,
a {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  font-family: inherit;
  appearance: none;
  color: inherit;
}
button {
  font-family: inherit;
  appearance: none;
  border: none;
  background-color: transparent;
  outline: none;
}
