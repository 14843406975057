@mixin content-width($wide: false) {
  inline-size: var(--content-width, 100%);
  margin-inline: auto;

  @if not $wide {
    max-inline-size: var(--content-max-width, 90rem);
  }
}

@mixin grid($wide: false) {
  display: grid;
  grid-template-columns: repeat(var(--grid-columns, 12), 1fr);
  gap: var(--grid-gutter, 1.25rem);

  @if not $wide {
    @include content-width;
  }
}
