@use '../mixins' as *;

:root {
  --duration-instant: 0;
  --duration-fast: 150ms;
  --duration-standard: 300ms;
  --duration-slow: 450ms;
  --duration-long: 1s;
}

@include reduced-motion {
  :root {
    --duration-instant: 0;
    --duration-fast: 0;
    --duration-standard: 0;
    --duration-slow: 0;
  }
}
